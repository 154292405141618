import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomTooltip from "../../utils/Tooltip";

export default function PasswordConfiguration({ config, setConfig }) {
  const handleInputChange = (field, value) => {
    const updatedConfig = { ...config };
    updatedConfig.password[field] = value;
    setConfig(updatedConfig);
  };

  return (
    <div style={{ textAlign: "left" }}>
      <h6 style={{ fontWeight: "bold" }}>Password Pattern</h6>
      <br />
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item xs={4}>
          <Typography textAlign="left">
            Minimum Length
            <CustomTooltip title="Set the minimum number of characters required for the password." />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <input
            type="number"
            min="0"
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
            value={config.password.passwordPattern.minLength}
            onChange={(e) => {
              const updatedConfig = { ...config };
              updatedConfig.password.passwordPattern.minLength = e.target.value;
              setConfig(updatedConfig);
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography textAlign="left">
            Password History
            <CustomTooltip title="Specifies the number of previously used passwords that cannot be reused again." />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <input
            type="number"
            min="0"
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
            value={config.password.numberOfRepeatsAllowed}
            onChange={(e) =>
              handleInputChange("numberOfRepeatsAllowed", e.target.value)
            }
          />
        </Grid>

        <Grid item xs={4}>
          <Typography textAlign="left">
            Password Expiry
            <CustomTooltip title="Password expiration in days." />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <input
            type="number"
            min="0"
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
            value={config.password.expiryDays}
            onChange={(e) => handleInputChange("expiryDays", e.target.value)}
          />
          <span style={{ paddingLeft: "12px" }}>days</span>
        </Grid>

        <Grid item xs={12}>
          <h6 style={{ fontWeight: "bold" }}>Account Lockout Settings</h6>
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign="left">
            Logout User After Inactivity Period
            <CustomTooltip title="Specify the inactivity duration (in minutes) after which a user account is automatically locked. Set to 0 to disable. Maximum is 1440 minutes." />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <input
            type="number"
            min="0"
            max="1440"
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
            value={config.password.inactiveUserLockout}
            onChange={(e) =>
              handleInputChange("inactiveUserLockout", e.target.value)
            }
          />
          <span style={{ paddingLeft: "12px" }}>minutes</span>
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign="left">
            Failed Login Attempts Before Lockout
            <CustomTooltip title="Enter the number of failed sign-in attempts that will cause a user account to be locked. Set 0 to disable." />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <input
            type="number"
            min="0"
            max="999"
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
            value={config.password.accountLockoutThreshold}
            onChange={(e) =>
              handleInputChange("accountLockoutThreshold", e.target.value)
            }
          />
        </Grid>

        <Grid item xs={4}>
          <Typography textAlign="left">
            Lockout Duration
            <CustomTooltip title="Enter the number of minutes that a locked-out account remains locked before automatically becoming unlocked (should be between 1 and 99,999)." />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <input
            type="number"
            min="1"
            max="99999"
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
            value={config.password.accountLockoutDuration}
            onChange={(e) =>
              handleInputChange("accountLockoutDuration", e.target.value)
            }
          />
          <span style={{ paddingLeft: "12px" }}>minutes</span>
        </Grid>

        <Grid item xs={4}>
          <Typography textAlign="left">
            Reset account lockout counter
            <CustomTooltip title="Enter the number of minutes after a failed sign-in attempt before the attempt counter resets to 0. This value must be greater than the lockout duration." />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <input
            type="number"
            min="0"
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
            value={config.password.resetAccountLockCounterAfter}
            onChange={(e) =>
              handleInputChange("resetAccountLockCounterAfter", e.target.value)
            }
          />
          <span style={{ paddingLeft: "12px" }}>minutes</span>
        </Grid>
        <Grid item xs={4}>
          <Typography textAlign="left">
            Account auto lock duration
            <CustomTooltip title="Enter the duration after which the user will be auto-locked once signed in" />
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <input
            type="number"
            min="0"
            style={{ width: "150px", padding: "8px", fontSize: "small" }}
            value={config.password.autoLockDuration}
            onChange={(e) =>
              handleInputChange("autoLockDuration", e.target.value)
            }
          />
          <span style={{ paddingLeft: "12px" }}>minutes</span>
        </Grid>
      </Grid>
    </div>
  );
}
