import { properties } from "../properties";
import api from "../services/api";

function fetchAllWithFilter(pageSize, pageNumber, searchText) {
  let url = `${properties.adminService}/registry-configuration`;
  if (searchText) {
    url += `?searchText=${searchText}`;
  }
  return api.get(url);
}

function createConfiguration(data) {
  return api.post(`${properties.adminService}/registry-configuration`, data);
}

function fetchSummaryDetail(id) {
  return api.get(`${properties.adminService}/registry-configuration/${id}`);
}

export { createConfiguration, fetchAllWithFilter, fetchSummaryDetail};
