import { Typography, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import ChartShimmering from "./ChartsShimmering";
import React, { useState, useEffect } from "react";
import "./css/chart-card.css";

function ChartCard({ heading, chart: Chart, style, dataLength, showFilter, setFeedback }) {
  const [loading, setLoading] = useState(true);
  const [totalDays, setTotalDays] = useState(7);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div className="card">
      <div className="card-heading">
      <Typography
        sx={{ fontSize: 16, padding: 2, fontWeight: "bold", textAlign: "left" }}
        color="text.secondary"
        gutterBottom
      >
        {heading}
      </Typography>
    {
      showFilter &&
      <FormControl size="small" sx={{marginRight:"15px"}}>
        <Select
          id="select-days"
          value={totalDays}
          onChange={(event)=>setTotalDays(event.target.value)}
          size="small"
        >
          <MenuItem value={7}>Last 7 days</MenuItem>
          <MenuItem value={15}>Last 15 days</MenuItem>
          <MenuItem value={30}>Last 30 days</MenuItem>
        </Select>
      </FormControl>
}
      </div>
      <hr style={{ marginTop: 0, borderColor: "#E8E8E8", borderWidth: 2 }} />
      <br/>
      <div style={style}>
        {loading ? (
          <ChartShimmering />
        ) : dataLength !==0 ? (
          <Chart 
            totalDays={totalDays}
            setFeedback={setFeedback}
          />
        ) : (
          "Nothing to display here"
        )}
      </div>
    </div>
  );
}

export default ChartCard;
