import { properties } from "../properties";
import api from "../services/api";

const controller = "reporting";

function getHighRiskEventCount() {
  return api.get(`${properties.reportingService}/high-risk/count`);
}

function getTopPoliciesBreached(count) {
  return api.get(
    `${properties.reportingService}/policies-breached/top?n=${count}`
  );
}

function getTopRulesBreached(count) {
  return api.get(
    `${properties.reportingService}/rules-breached/top?n=${count}`
  );
}

function getIncidentsPerDay(fromDate, toDate) {
  return api.get(
    `${properties.reportingService}/incidents/per-day?fromDate=${fromDate}&toDate=${toDate}`
  );
}

function getTopRiskyUsers(count, fromDate, toDate) {
  const params = new URLSearchParams({ n: count });
  fromDate && params.set('fromDate', fromDate);
  toDate && params.set('toDate', toDate);
  return api.get(`${properties.reportingService}/risky-users/top?${params}`);
}

function getTopRiskyDevices(count, fromDate, toDate) {
  const params = new URLSearchParams({ n: count });
  fromDate && params.set('fromDate', fromDate);
  toDate && params.set('toDate', toDate);
  return api.get(`${properties.reportingService}/risky-devices/top?${params}`);
}

function getDeviceReportingList(
  destinationId,
  pageSize,
  pageNumber,
  searchText,
  startTime,
  endTime,
  groupId
) {
  let url = `${properties.reportingService}?`;
  if (destinationId != null && destinationId != "all")
    url += `destinationId=${destinationId}&`;
  if (startTime && startTime.isValid()) {
    url += `startDttm=${new Date(startTime).toISOString()}&`;
  }
  if (endTime && endTime.isValid()){
    url += `endDttm=${new Date(endTime).toISOString()}&`;
  }
  if (groupId != null && groupId != "all"){
    url += `groupId=${groupId}&`;
  }
  url += `pageSize=${pageSize}&pageNumber=${pageNumber}&reportType=DEVICE&searchText=${searchText}`;
  return api.get(url);
}

function downloadReport(destinationId, searchText, startTime, endTime, groupId) {
  let url = `${properties.reportingService}/download?`;
  if (destinationId != null && destinationId != "all")
    url += `destinationId=${destinationId}&`;
  if (startTime && startTime.isValid()) {
    url += `startDttm=${new Date(startTime).toISOString()}&`;
  }
  if (endTime && endTime.isValid())
    url += `endDttm=${new Date(endTime).toISOString()}&`;
  if (destinationId == 2) {
    url += "reportType=USER&";
  } else {
    url += "reportType=DEVICE&";
  }
  if (groupId){
    url += `groupId=${groupId}&`;
  }
  url += `searchText=${searchText}`;
  return api.get(url);
}

function getEmailReportingList(
  destinationId,
  pageSize,
  pageNumber,
  searchText,
  startTime,
  endTime,
  groupId
) {
  let url = `${properties.reportingService}?`;
  if (destinationId != null && destinationId != "all")
    url += `destinationId=${destinationId}&`;
  if (startTime && startTime.isValid()) {
    url += `startDttm=${new Date(startTime).toISOString()}&`;
  }
  if (endTime && endTime.isValid()){
    url += `endDttm=${new Date(endTime).toISOString()}&`;
  }
  if (groupId != null && groupId !== "all"){
    url += `groupId=${groupId}&`;
  }
  url += `pageSize=${pageSize}&pageNumber=${pageNumber}&reportType=USER&searchText=${searchText}`;
  return api.get(url);
}

export {
  getHighRiskEventCount,
  getTopPoliciesBreached,
  getTopRulesBreached,
  getIncidentsPerDay,
  getTopRiskyUsers,
  getTopRiskyDevices,
  getDeviceReportingList,
  getEmailReportingList,
  downloadReport
};
