import React, { useState, useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { getTopRiskyUsers } from "../../API/reporting";
import BarChartWrapper from "./BarChartWrapper";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function RiskyUsersBarChart({ totalDays, setFeedback }) {
    const [barChartValuesAndLabels, setBarChartValuesAndLabels] = useState([]);
    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);
    const axisLabels = {
        x: "Number of incidents",
        y: "Users"
    }
    const setBarChartValuesAndLabelsData = (data) => {
        const barChartArray = data.map((item) => {
            const label = item.name || "Deleted User";
            return { value: parseInt(item.value, 10), label };
        });
        setBarChartValuesAndLabels(barChartArray);
    };

    const loadData = async () => {
        try {
            let fromDate = new Date();
            fromDate.setDate(new Date().getDate() - totalDays);
            fromDate.setHours(0, 0, 0, 0);
            let toDate = new Date();
            toDate.setDate(toDate.getDate() + 1);
            
            const response = await getTopRiskyUsers(5, fromDate.toISOString(), toDate.toISOString());
            setBarChartValuesAndLabelsData(response.data);
        } catch (error) {
            setFeedback({
                severity: "error",
                message: "There is an issue fetching risky users",
                state: true
            });
        }
    };

    useEffect(() => {
        loadData();
    }, [totalDays]);

    useEffect(() => {
        const newLabels = barChartValuesAndLabels.map((item) => item.label);
        const newData = barChartValuesAndLabels.map((item) => item.value);
        setLabels(newLabels);
        setData(newData);
    }, [barChartValuesAndLabels]);

    const backgroundColor = "#6CA7E7";
    const indexAxis = "y" // horizontal layout
    return barChartValuesAndLabels.length > 0 ? <BarChartWrapper data={data} labels={labels} axisLabels={axisLabels} backgroundColor={backgroundColor} indexAxis={indexAxis} /> : `No risky users found in last ${totalDays} days`;

}
