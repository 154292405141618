import { Grid, Button, Typography, Tooltip } from "@mui/material";
import StyledSwitch from "../../utility/StyledSwitch";
import { applyRemoteWipeToDevice, applyPasswordPolicy, enablePasswordManager } from "../../../API/devices";
import RemoteLockWithConfirmation from "../device_actions/RemoteLockWithConfirmation";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { calculateLastActive } from "../../../utils/Helpers";
import { DIALOG_TYPES, REMOTE_LOCK_STATUS, REMOTE_WIPE_STATUS } from "../../../utils/Constants";
import { Info } from "@mui/icons-material";
import CustomDialog from "../../utility/Dialog";

export default function DefaultProfileActionsTab({
  deviceData,
  setDeviceData,
  setFeedback
}) {
  const navigate = useNavigate();
  const deviceId = deviceData.id;
  const [confirmRemoteLock, setConfirmRemoteLock] = useState(false);
  const [confirmRemoteWipe, setConfirmRemoteWipe] = useState(false);

  const handleUpdateIsPasswordChangePolicyApplied = (event) => {
    const isChecked = event.target.checked;
    const message = isChecked ? "applied" : "is no longer applied";

    applyPasswordPolicy(deviceId, isChecked)
      .then(() => {
        setFeedback({
          severity: "success",
          message: `Password policy ${message} for the device`,
          state: true
        });
        setDeviceData((prevDeviceData) => ({
          ...prevDeviceData,
          isPasswordChangePolicyApplied: isChecked
        }));
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      });
  };

  const handleUpdateIsPasswordManagerEnabled = (event) => {
    const isChecked = event.target.checked;
    const message = !isChecked ? "enabled" : "disabled";
    enablePasswordManager(deviceId, isChecked)
      .then(() => {
        setFeedback({
          severity: "success",
          message: `Password manager ${message} for the device`,
          state: true
        });
        setDeviceData((prevDeviceData) => ({
          ...prevDeviceData,
          isPasswordManagerEnabled: isChecked
        }));
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while updating.",
          state: true
        });
      });
  };

  const handleRemoteWipeConfirmed = () => {
    applyRemoteWipeToDevice(deviceId)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: "Device wipe initiated.",
          state: true
        });
        setConfirmRemoteWipe(false);

        const remoteWipeRequestedTimestamp = new Date();
        if (setDeviceData !== undefined) {
          setDeviceData((prevDeviceData) => ({
            ...prevDeviceData,
            remoteWipeRequestedTimestamp,
            remoteWipeStatus: REMOTE_WIPE_STATUS.REQUESTED
          }));
        }
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while initiating wipe the device",
          state: true
        });
      });
  }

  return (
    <>
      <Grid container columns={24} className="device-actions" spacing={3}>
        <Grid item md={6} className="device-actions-heading">
          Apply Password Policy :
        </Grid>
        <Grid item md={18}>
          <StyledSwitch
            checked={deviceData.isPasswordChangePolicyApplied}
            onChange={(event) =>
              handleUpdateIsPasswordChangePolicyApplied(event)
            }
          />
          &emsp;|&emsp;
          <Button
            onClick={() =>
              navigate("/settings/password-policy", {
                state: { tabValue: 1 }
              })
            }
          >
            [ Configure Password Policy ]
          </Button>
        </Grid>
        <Grid item md={6} className="device-actions-heading">
          Remote Lock :
        </Grid>
        <Grid item md={18} style={{ display: "flex", alignItems: "center" }}>
          <Button variant="outlined" onClick={() => setConfirmRemoteLock(true)}>
            Apply Remote Lock
          </Button>
          <Typography style={{ marginLeft: "10px" }}>
            (
            {deviceData.remoteLockStatus === REMOTE_LOCK_STATUS.REQUESTED
              ? `Last requested: ${calculateLastActive(
                  new Date(deviceData.remoteLockRequestedTimestamp + "Z")
                )}`
              : deviceData.remoteLockStatus === REMOTE_LOCK_STATUS.APPLIED
              ? `Last applied: ${calculateLastActive(
                  new Date(deviceData.remoteLockAppliedTimestamp + "Z")
                )}`
              : "Remote Lock Not Requested"}
            )
          </Typography>
        </Grid>
        <Grid item md={6}>
          <div className="device-actions-heading">Remote Wipe :</div>
          <div className="warning-text">
            <b>Caution:</b> This action will permanently erase all data from the
            device. Once deleted, the data cannot be recovered.
          </div>
        </Grid>
        <Grid item md={18}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setConfirmRemoteWipe(true)}
            >
              Apply Remote Wipe
            </Button>
            <Typography style={{ marginLeft: "10px" }}>
              (
              {deviceData.remoteWipeStatus === REMOTE_WIPE_STATUS.REQUESTED
                ? `Last requested: ${calculateLastActive(
                    new Date(deviceData.remoteWipeRequestedTimestamp + "Z")
                  )}`
                : deviceData.remoteWipeStatus === REMOTE_WIPE_STATUS.APPLIED
                ? `Last applied: ${calculateLastActive(
                    new Date(deviceData.remoteWipeInitiatedTimestamp + "Z")
                  )}`
                : "Remote Wipe Not Requested"}
              )
            </Typography>
          </div>
        </Grid>
        <Grid item md={6} className="device-actions-heading">
          Disable Password Manager (Google Chrome) :
        </Grid>
        <Grid item md={18}>
          <StyledSwitch
            checked={deviceData.isPasswordManagerEnabled}
            onChange={(event) => handleUpdateIsPasswordManagerEnabled(event)}
          />
        </Grid>
      </Grid>
      <RemoteLockWithConfirmation
        confirmRemoteLock={confirmRemoteLock}
        setConfirmRemoteLock={setConfirmRemoteLock}
        deviceId={deviceId}
        setFeedback={setFeedback}
        setDeviceData={setDeviceData}
      />
      <CustomDialog
        open={confirmRemoteWipe}
        setOpen={setConfirmRemoteWipe}
        title="Are you sure you want to initiate a remote wipe on this device?"
        content="Once started, this action cannot be undone, and all data on the device will be permanently erased."
        handleConfirm={handleRemoteWipeConfirmed}
        dialogType={DIALOG_TYPES.CONFIRM}
        getAcknowledge={true}
      />
    </>
  );
}
