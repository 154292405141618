import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@mui/material";
import { useRef } from "react";
import { useState } from "react";
import { CheckPermissionGivenUserPrivileges } from "./CheckPermissionGivenUserPrivileges";
import { useSelector } from "react-redux";

export default function Actions({
  actionList = [],
  recordId,
  getActionListHandler,
  style
}) {
  const userPrivileges = useSelector((state) => state.auth.privileges);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (getActionListHandler !== undefined)
    actionList = getActionListHandler(recordId);

  const filteredActionList = actionList.filter((element) =>
    CheckPermissionGivenUserPrivileges({
      userPrivileges,
      requiredPrivileges: element.requiredPrivileges
    })
  );

  return style == "buttons" ? (
    <div>
      {filteredActionList.map((action, index) => (
        <Button
          key={index}
          size="small"
          color={action.color}
          startIcon={action.icon}
          onClick={() => {
            action.onClickHandler(recordId);
          }}
        >
          {action.label}
        </Button>
      ))}
    </div>
  ) : (
    <div>
      <Button
        ref={anchorRef}
        sx={{ padding: 0 }}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MoreVertIcon sx={{ color: "#6b778c" }} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  className="box-shadow"
                >
                  {filteredActionList.length === 0 ? (
                    <MenuItem disabled>{"No actions"}</MenuItem>
                  ) : (
                    filteredActionList.map((action, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setOpen(false);
                          action.onClickHandler(recordId);
                        }}
                      >
                        {action.label}
                      </MenuItem>
                    ))
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
