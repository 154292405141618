const ADMIN_PRIVILEGE = "ACCESS_ADMIN_ENDPOINTS";

const PRIVILEGES = {
  VIEW_DEVICE_LIST: { title: "View Device List", name: "VIEW_DEVICE_LIST" },
  VIEW_DEVICE_GROUPS: {
    title: "View Device Groups",
    name: "VIEW_DEVICE_GROUPS"
  },
  MANAGE_DEVICE_APPROVALS: {
    title: "Manage Device Approvals",
    name: "MANAGE_DEVICE_APPROVALS"
  },
  CREATE_DEVICE_GROUP: {
    title: "Create Device Group",
    name: "CREATE_DEVICE_GROUP"
  },
  EDIT_DEVICE_GROUP: { title: "Edit Device Group", name: "EDIT_DEVICE_GROUP" },
  DELETE_DEVICE_GROUP: {
    title: "Delete Device Group",
    name: "DELETE_DEVICE_GROUP"
  },

  VIEW_POLICY: { title: "View Policy", name: "VIEW_POLICY" },
  CREATE_POLICY: { title: "Create Policy", name: "CREATE_POLICY" },
  EDIT_POLICY: { title: "Edit Policy", name: "EDIT_POLICY" },
  DELETE_POLICY: { title: "Delete Policy", name: "DELETE_POLICY" },

  VIEW_USER_LIST: { title: "View User List", name: "VIEW_USER_LIST" },
  CREATE_EMAIL_USER: { title: "Create Email User", name: "CREATE_EMAIL_USER" },
  EDIT_EMAIL_USER: { title: "Edit Email User", name: "EDIT_EMAIL_USER" },
  DELETE_EMAIL_USER: { title: "Delete Email User", name: "DELETE_EMAIL_USER" },

  VIEW_USER_GROUPS: { title: "View User Groups", name: "VIEW_USER_GROUPS" },
  CREATE_EMAIL_USER_GROUP: {
    title: "Create Email User Group",
    name: "CREATE_EMAIL_USER_GROUP"
  },
  EDIT_EMAIL_USER_GROUP: {
    title: "Edit Email User Group",
    name: "EDIT_EMAIL_USER_GROUP"
  },
  DELETE_EMAIL_USER_GROUP: {
    title: "Delete Email User Group",
    name: "DELETE_EMAIL_USER_GROUP"
  },

  VIEW_NOTIFICATION: { title: "View Notification", name: "VIEW_NOTIFICATION" },
  CREATE_NOTIFICATION: {
    title: "Create Notification",
    name: "CREATE_NOTIFICATION"
  },
  EDIT_NOTIFICATION: { title: "Edit Notification", name: "EDIT_NOTIFICATION" },
  DELETE_NOTIFICATION: {
    title: "Delete Notification",
    name: "DELETE_NOTIFICATION"
  },

  VIEW_CLASSIFICATION: {
    title: "View Classification",
    name: "VIEW_CLASSIFICATION"
  },
  CREATE_CLASSIFICATION: {
    title: "Create Classification",
    name: "CREATE_CLASSIFICATION"
  },
  EDIT_CLASSIFICATION: {
    title: "Edit Classification",
    name: "EDIT_CLASSIFICATION"
  },
  DELETE_CLASSIFICATION: {
    title: "Delete Classification",
    name: "DELETE_CLASSIFICATION"
  },

  VIEW_RULE: { title: "View Rule", name: "VIEW_RULE" },
  CREATE_RULE: { title: "Create Rule", name: "CREATE_RULE" },
  EDIT_RULE: { title: "Edit Rule", name: "EDIT_RULE" },
  DELETE_RULE: { title: "Delete Rule", name: "DELETE_RULE" },

  VIEW_APPROVAL: { title: "View Approval", name: "VIEW_APPROVAL" },
  CREATE_APPROVAL: { title: "Create Approval", name: "CREATE_APPROVAL" },
  EDIT_APPROVAL: { title: "Edit Approval", name: "EDIT_APPROVAL" },

  VIEW_ORGANIZATIONAL_DOMAIN: {
    title: "View Organizational Domain",
    name: "VIEW_ORGANIZATIONAL_DOMAIN"
  },
  CREATE_ORGANIZATIONAL_DOMAIN: {
    title: "Create Organizational Domain",
    name: "CREATE_ORGANIZATIONAL_DOMAIN"
  },
  EDIT_ORGANIZATIONAL_DOMAINS: {
    title: "Edit Organizational Domains",
    name: "EDIT_ORGANIZATIONAL_DOMAINS"
  },
  DELETE_ORGANIZATIONAL_DOMAINS: {
    title: "Delete Organizational Domains",
    name: "DELETE_ORGANIZATIONAL_DOMAINS"
  },

  VIEW_REPORTS: { title: "View Reports", name: "VIEW_REPORTS" },

  VIEW_APP_CATEGORY: { title: "View App Category", name: "VIEW_APP_CATEGORY" },
  CREATE_APP_CATEGORY: {
    title: "Create App Category",
    name: "CREATE_APP_CATEGORY"
  },
  EDIT_APP_CATEGORY: { title: "Edit App Category", name: "EDIT_APP_CATEGORY" },
  DELETE_APP_CATEGORY: {
    title: "Delete App Category",
    name: "DELETE_APP_CATEGORY"
  },

  VIEW_APP: { title: "View App", name: "VIEW_APP" },
  CREATE_APP: { title: "Create App", name: "CREATE_APP" },
  DELETE_APP: { title: "Delete App", name: "DELETE_APP" },

  CONFIGURE_ACTIVE_DIRECTORY: {
    title: "Configure Active Directory",
    name: "CONFIGURE_ACTIVE_DIRECTORY"
  },
  CONFIGURE_PASSWORD_POLICY: {
    title: "Configure Password Policy",
    name: "CONFIGURE_PASSWORD_POLICY"
  },
  CONFIGURE_SMTP: { title: "Configure SMTP", name: "CONFIGURE_SMTP" },
  CONFIGURE_REGISTRY: { title: "Configure Registry", name: "CONFIGURE_REGISTRY" },
  CONFIGURE_EMAIL_TEMPLATE: {
    title: "Configure Email Template",
    name: "CONFIGURE_EMAIL_TEMPLATE"
  },
  DOWNLOAD_MODULE: {
    title: "Download Module",
    name: "DOWNLOAD_MODULE"
  },
  VIEW_DOMAIN_AND_CATEGORY: {
    title: "View Domain and Categories",
    name: "VIEW_DOMAIN_AND_CATEGORY"
  },
  MODIFY_DOMAIN_AND_CATEGORY: {
    title: "Edit Domain and Categories",
    name: "MODIFY_DOMAIN_AND_CATEGORY"
  },
  DELETE_DOMAIN_AND_CATEGORY: {
    title: "Delete Domain and Categories",
    name: "DELETE_DOMAIN_AND_CATEGORY"
  },
  VIEW_USB: {
    title: "View USB",
    name: "VIEW_USB"
  },
  ADD_USB: {
    title: "Add USB",
    name: "ADD_USB"
  },
  EDIT_USB: {
    title: "Edit USB",
    name: "EDIT_USB"
  },
  DELETE_USB: {
    title: "Delete USB",
    name: "DELETE_USB"
  }
};

const PRIVILEGE_GROUP_LIST = [
  {
    title: "Manage Device",
    privileges: [PRIVILEGES.VIEW_DEVICE_LIST, PRIVILEGES.DOWNLOAD_MODULE, PRIVILEGES.MANAGE_DEVICE_APPROVALS]
  },
  {
    title: "Manage Device Groups",
    privileges: [
      PRIVILEGES.VIEW_DEVICE_GROUPS,
      PRIVILEGES.CREATE_DEVICE_GROUP,
      PRIVILEGES.EDIT_DEVICE_GROUP,
      PRIVILEGES.DELETE_DEVICE_GROUP
    ]
  },
  {
    title: "Manage Users",
    privileges: [
      PRIVILEGES.VIEW_USER_LIST,
      PRIVILEGES.CREATE_EMAIL_USER,
      PRIVILEGES.EDIT_EMAIL_USER,
      PRIVILEGES.DELETE_EMAIL_USER
    ]
  },
  {
    title: "Manage User Groups",
    privileges: [
      PRIVILEGES.VIEW_USER_GROUPS,
      PRIVILEGES.CREATE_EMAIL_USER_GROUP,
      PRIVILEGES.EDIT_EMAIL_USER_GROUP,
      PRIVILEGES.DELETE_EMAIL_USER_GROUP
    ]
  },
  {
    title: "Manage Apps",
    privileges: [
      PRIVILEGES.VIEW_APP,
      PRIVILEGES.CREATE_APP,
      PRIVILEGES.DELETE_APP
    ]
  },
  {
    title: "Manage App Categories",
    privileges: [
      PRIVILEGES.VIEW_APP_CATEGORY,
      PRIVILEGES.CREATE_APP_CATEGORY,
      PRIVILEGES.EDIT_APP_CATEGORY,
      PRIVILEGES.DELETE_APP_CATEGORY
    ]
  },
  {
    title: "Manage USBs",
    privileges: [
      PRIVILEGES.VIEW_USB,
      PRIVILEGES.ADD_USB,
      PRIVILEGES.EDIT_USB,
      PRIVILEGES.DELETE_USB
    ]
  },
  {
    title: "Manage Domain and Categories",
    privileges: [
      PRIVILEGES.VIEW_DOMAIN_AND_CATEGORY,
      PRIVILEGES.MODIFY_DOMAIN_AND_CATEGORY,
      PRIVILEGES.DELETE_DOMAIN_AND_CATEGORY
    ]
  },
  {
    title: "Manage Rules",
    privileges: [
      PRIVILEGES.VIEW_RULE,
      PRIVILEGES.CREATE_RULE,
      PRIVILEGES.EDIT_RULE,
      PRIVILEGES.DELETE_RULE
    ]
  },
  {
    title: "Manage Classification",
    privileges: [
      PRIVILEGES.VIEW_CLASSIFICATION,
      PRIVILEGES.CREATE_CLASSIFICATION,
      PRIVILEGES.EDIT_CLASSIFICATION,
      PRIVILEGES.DELETE_CLASSIFICATION
    ]
  },
  {
    title: "Manage Policy",
    privileges: [
      PRIVILEGES.VIEW_POLICY,
      PRIVILEGES.CREATE_POLICY,
      PRIVILEGES.EDIT_POLICY,
      PRIVILEGES.DELETE_POLICY
    ]
  },
  {
    title: "Manage Notifications",
    privileges: [
      PRIVILEGES.VIEW_NOTIFICATION,
      PRIVILEGES.CREATE_NOTIFICATION,
      PRIVILEGES.EDIT_NOTIFICATION,
      PRIVILEGES.DELETE_NOTIFICATION
    ]
  },
  {
    title: "Manage Approval",
    privileges: [
      PRIVILEGES.VIEW_APPROVAL,
      PRIVILEGES.CREATE_APPROVAL,
      PRIVILEGES.EDIT_APPROVAL
    ]
  },
  {
    title: "Manage Organizational Domains",
    privileges: [
      PRIVILEGES.VIEW_ORGANIZATIONAL_DOMAIN,
      PRIVILEGES.CREATE_ORGANIZATIONAL_DOMAIN,
      PRIVILEGES.EDIT_ORGANIZATIONAL_DOMAINS,
      PRIVILEGES.DELETE_ORGANIZATIONAL_DOMAINS
    ]
  },
  {
    title: "Manage Reports",
    privileges: [PRIVILEGES.VIEW_REPORTS]
  },
  {
    title: "Manage Configurations",
    privileges: [
      PRIVILEGES.CONFIGURE_ACTIVE_DIRECTORY,
      PRIVILEGES.CONFIGURE_PASSWORD_POLICY,
      PRIVILEGES.CONFIGURE_SMTP,
      PRIVILEGES.CONFIGURE_EMAIL_TEMPLATE,
      PRIVILEGES.CONFIGURE_REGISTRY,
    ]
  }
];

export { PRIVILEGES, PRIVILEGE_GROUP_LIST, ADMIN_PRIVILEGE };
