import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  TextField,
  Toolbar,
  Divider,
  IconButton,
  Stack,
  InputLabel
} from "@mui/material";
import { updateDisplayName } from "../../../API/devices";
import "../../../App.css";
import "../device-list.css";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";

function DeviceForm({ setFeedback, setOpen, open, deviceData, setDeviceData }) {
  useEffect(() => {
    if (deviceData) {
      formik.setValues({
        id: deviceData.id,
        displayName: deviceData.displayName ? deviceData.displayName : ""
      });
    }
  }, [deviceData]);

  const validate = Yup.object({
    displayName: Yup.string().max(20, "Must be 20 characters or less")
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      displayName: ""
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values);
    }
  });

  function submitForm(values) {
    updateDisplayName(deviceData.id, values.displayName)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: "Device Name updated successfully",
          state: true
        });
        setDeviceData((prevDeviceData) => {
          return {
            ...prevDeviceData,
            displayName: values.displayName
          };
        });
        setOpen(false);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while updating display name.",
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        <form onSubmit={formik.handleSubmit}>
          <h5>Edit Device</h5>
          <Divider />
          <div className="form-input">
            <InputLabel htmlFor="id" className="form-input-label" required>
              ID
            </InputLabel>
            <TextField
              id="id"
              className="form-textfield"
              fullWidth
              size="small"
              value={formik.values.id}
              disabled
            />
          </div>
          <div className="form-input">
            <InputLabel htmlFor="display-name" className="form-input-label">
              Display Name
            </InputLabel>
            <TextField
              id="display-name"
              placeholder="Enter Name"
              className="form-textfield"
              size="small"
              fullWidth
              name="displayName"
              value={formik.values.displayName}
              onChange={formik.handleChange}
              error={
                formik.touched.displayName && Boolean(formik.errors.displayName)
              }
              helperText={
                formik.touched.displayName && formik.errors.displayName
              }
            />
          </div>
          <br />
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              className="primary-button-outlined"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="primary-button-filled"
              variant="contained"
              type="submit"
              startIcon={<BeenhereOutlinedIcon />}
            >
              Update
            </Button>
          </Stack>
        </form>
      </div>
    </div>
  );
}

export default DeviceForm;
