import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Toolbar,
  IconButton,
  Divider,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Grid
} from "@mui/material";
import { useEffect } from "react";
import FormShimmering from "../utility/FormShimmer";
import CloseIcon from "@mui/icons-material/Close";
import FormFooter from "../utility/FormFooter";
import { createConfiguration } from "../../API/registryConfiguration";
import { fetchAndFormatDevices } from "../../utils/Helpers";
import CheckboxSelectorListWithSearch from "../utility/CheckBoxSelectorListWithSearch";
import { REGISTRY_ACTION } from "../../utils/Constants";

function RegistryEditorForm({ setFeedback, setOpen, open, setFetchCount }) {
  let formType = "Add";

  const [loading, setLoading] = useState(false);
  
  const REGISTRY_DATA_TYPES = [
    "REG_SZ",
    "REG_BINARY",
    "REG_DWORD",
    "REG_EXPAND_SZ",
    "REG_MULTI_SZ",
    "REG_QWORD"
  ];

  useEffect(() => {
    formik.resetForm();
  }, [open]);

  const validate = Yup.object({
    name: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Required"),
    action: Yup.string().required("Required"),
    subKey: Yup.string().required("Required"),
    valueName: Yup.string().when("action", {
      is: (action) =>
        action !== REGISTRY_ACTION.ADD_KEY.value &&
        action !== REGISTRY_ACTION.DELETE_KEY.value,
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired()
    }),
    valueData: Yup.string().when("action", {
      is: (action) =>
        action !== REGISTRY_ACTION.ADD_KEY.value &&
        action !== REGISTRY_ACTION.DELETE_KEY.value &&
        action !== REGISTRY_ACTION.DELETE_VALUE.value,
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired()
    }),
    dataType: Yup.string().when("action", {
      is: (action) =>
        action !== REGISTRY_ACTION.ADD_KEY.value &&
        action !== REGISTRY_ACTION.DELETE_KEY.value &&
        action !== REGISTRY_ACTION.DELETE_VALUE.value,
      then: Yup.string().required("Required"),
      otherwise: Yup.string().notRequired()
    }),
    deviceIds: Yup.array().min(1, "Please select at least one device.")
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      action: REGISTRY_ACTION.WRITE_VALUE.value,
      headerKey: "HKEY_LOCAL_MACHINE",
      subKey: "",
      dataType: "REG_SZ",
      valueName: "",
      valueData: "",
      deviceIds: []
    },
    validationSchema: validate,
    onSubmit: (values, { resetForm }) => {
      submitForm(values, resetForm);
    }
  });

  function submitForm(values, resetForm) {
    const data = {
      name: values.name,
      action: values.action,
      deviceIds: values.deviceIds,
      configuration: {
        headerKey: values.headerKey,
        subKey: values.subKey
      }
    };
    if (values.action === REGISTRY_ACTION.WRITE_VALUE.value) {
      data.configuration = {
        ...data.configuration,
        dataType: values.dataType,
        valueName: values.valueName,
        valueData: values.valueData
      };
    } else if (values.action === REGISTRY_ACTION.DELETE_VALUE.value) {
      data.configuration = {
        ...data.configuration,
        valueName: values.valueName
      };
    }
    createConfiguration(data)
      .then((response) => {
        setFeedback({
          severity: "success",
          message: "Configuration added successfully",
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setOpen(false);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while adding configuration.",
          state: true
        });
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={`drawer-form-wrapper form-width ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={handleClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        {loading ? (
          <FormShimmering />
        ) : (
          <form>
            <h5>Configure Registry Settings</h5>
            <Divider />
            <Grid
              container
              spacing={2}
              className="group-form-container"
              columns={24}
            >
              <Grid item md={12}>
                <div className="form-input">
                  <InputLabel
                    htmlFor="name"
                    className="form-input-label"
                    required
                  >
                    Configuration Name
                  </InputLabel>
                  <TextField
                    size="small"
                    id="name"
                    placeholder="New Configuration"
                    className="form-textfield"
                    fullWidth
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>

                <div className="form-input">
                  <InputLabel
                    htmlFor="action"
                    className="form-input-label"
                    required
                  >
                    Action
                  </InputLabel>
                  <Select
                    id="action"
                    name="action"
                    value={formik.values.action}
                    onChange={formik.handleChange}
                    fullWidth
                    size="small"
                    error={
                      formik.touched.action && Boolean(formik.errors.action)
                    }
                  >
                    <MenuItem value={REGISTRY_ACTION.WRITE_VALUE.value}>
                      {REGISTRY_ACTION.WRITE_VALUE.label}
                    </MenuItem>
                    <MenuItem value={REGISTRY_ACTION.DELETE_VALUE.value}>
                      {REGISTRY_ACTION.DELETE_VALUE.label}
                    </MenuItem>
                    <MenuItem value={REGISTRY_ACTION.ADD_KEY.value}>
                      {REGISTRY_ACTION.ADD_KEY.label}
                    </MenuItem>
                    <MenuItem value={REGISTRY_ACTION.DELETE_KEY.value}>
                      {REGISTRY_ACTION.DELETE_KEY.label}
                    </MenuItem>
                  </Select>
                  {formik.touched.action && formik.errors.action && (
                    <div className="error-text">{formik.errors.action}</div>
                  )}
                </div>

                <div className="form-input">
                  <InputLabel
                    htmlFor="headerKey"
                    className="form-input-label"
                    required
                  >
                    Header Key
                  </InputLabel>
                  <TextField
                    size="small"
                    id="headerKey"
                    value={formik.values.headerKey}
                    disabled
                    className="form-textfield"
                    fullWidth
                  />
                </div>

                <div className="form-input">
                  <InputLabel
                    htmlFor="subKey"
                    className="form-input-label"
                    required
                  >
                    Sub-Key
                  </InputLabel>
                  <TextField
                    size="small"
                    id="subKey"
                    placeholder="e.g. \SOFTWARE\MyApp"
                    className="form-textfield"
                    fullWidth
                    name="subKey"
                    value={formik.values.subKey}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.subKey && Boolean(formik.errors.subKey)
                    }
                    helperText={formik.touched.subKey && formik.errors.subKey}
                  />
                </div>
                {formik.values.action !== REGISTRY_ACTION.ADD_KEY.value &&
                  formik.values.action !== REGISTRY_ACTION.DELETE_KEY.value && (
                    <>
                      {formik.values.action !==
                        REGISTRY_ACTION.DELETE_VALUE.value && (
                        <div className="form-input">
                          <InputLabel
                            htmlFor="dataType"
                            className="form-input-label"
                            required
                          >
                            Data Type
                          </InputLabel>
                          <Select
                            id="dataType"
                            name="dataType"
                            value={formik.values.dataType}
                            onChange={formik.handleChange}
                            fullWidth
                            size="small"
                            error={
                              formik.touched.dataType &&
                              Boolean(formik.errors.dataType)
                            }
                          >
                            {REGISTRY_DATA_TYPES.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.dataType &&
                            formik.errors.dataType && (
                              <div className="error-text">
                                {formik.errors.dataType}
                              </div>
                            )}
                        </div>
                      )}
                      <div className="form-input">
                        <InputLabel
                          htmlFor="valueName"
                          className="form-input-label"
                          required
                        >
                          Value Name
                        </InputLabel>
                        <TextField
                          size="small"
                          id="valueName"
                          placeholder="Enter Value Name"
                          className="form-textfield"
                          fullWidth
                          name="valueName"
                          value={formik.values.valueName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.valueName &&
                            Boolean(formik.errors.valueName)
                          }
                          helperText={
                            formik.touched.valueName && formik.errors.valueName
                          }
                        />
                      </div>
                      {formik.values.action !==
                        REGISTRY_ACTION.DELETE_VALUE.value && (
                        <div className="form-input">
                          <InputLabel
                            htmlFor="valueData"
                            className="form-input-label"
                            required
                          >
                            Value Data
                          </InputLabel>
                          <TextField
                            multiline
                            size="small"
                            id="valueData"
                            placeholder="Enter Value Data"
                            className="form-textfield"
                            fullWidth
                            name="valueData"
                            value={formik.values.valueData}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.valueData &&
                              Boolean(formik.errors.valueData)
                            }
                            helperText={
                              formik.touched.valueData &&
                              formik.errors.valueData
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
              </Grid>
              <Grid md={1} item sx={{ Height: "100%" }}>
                <Divider orientation="vertical" sx={{ opacity: 1 }} />
              </Grid>
              <Grid item md={11}>
                <br />
                {formik.touched.deviceIds && formik.errors.deviceIds && (
                  <div className="error-text">{formik.errors.deviceIds}</div>
                )}
                <CheckboxSelectorListWithSearch
                  heading="Select Devices"
                  setFeedback={setFeedback}
                  fetchAndFormatFields={fetchAndFormatDevices}
                  selectedRecords={formik.values.deviceIds}
                  setSelectedRecords={(values) => {
                    formik.setFieldValue("deviceIds", values);
                  }}
                  options={{
                    showFilters: true,
                    showSearchBar: true
                  }}
                  maxHeight="430px"
                  isRequired={true}
                />
              </Grid>
            </Grid>
            <br />
            <FormFooter
              formType={formType}
              handleSubmit={formik.handleSubmit}
              handleClose={handleClose}
            />
          </form>
        )}
      </div>
    </div>
  );
}

export default RegistryEditorForm;
