import React, { useState, useEffect } from "react";
import TableComponent from "../utility/Table";
import {
  getDeviceApprovalRequests as getDeviceApprovalRequestsAPI,
  updateApprovalStatus as updateApprovalStatusAPI
} from "../../API/devices";
import {
  DEVICE_APPROVAL_STATUS,
  DEVICE_APPROVAL_STATUS_KEYS,
  MESSAGES
} from "../../utils/Constants";
import MultiFilter from "../utility/MultiFilter";
import PageTitle from "../utility/PageTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import { capitalizefirstLetter } from "../../utils/Helpers";
import Pagination from "../utility/Pagination";

function DeviceApprovalRequests({ setFeedback }) {
  const [approvalRequests, setApprovalRequests] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [approvalRequestMap, setApprovalRequestMap] = useState({});
  const [filterValuesList, setFilterValuesList] = useState({
    Status: []
  });
  const [filtersSelectedValue, setFiltersSelectedValue] = useState({
    Version: "all",
    Status: "all"
  });

  function getStatusList() {
    let statusList = [];
    Object.keys(DEVICE_APPROVAL_STATUS).forEach((key) => {
      statusList.push({
        filterValue: DEVICE_APPROVAL_STATUS[key],
        filterId: key
      });
    });
    return statusList;
  }

  useEffect(() => {
    setFilterValuesList((prevState) => {
      return {
        ...prevState,
        Status: getStatusList()
      };
    });
  }, []);

  useEffect(() => {
    getApprovalRequests();
  }, [filtersSelectedValue, searchText, pageNumber, pageSize]);

  function getApprovalRequests() {
    getDeviceApprovalRequestsAPI(
      pageNumber,
      pageSize,
      searchText,
      filtersSelectedValue.Status
    )
      .then(({ data: { deviceApprovalRequests, totalCount } }) => {
        const modifiedDeviceApprovalRequests = deviceApprovalRequests.map((approvalRequest) => {
          return {
            ...approvalRequest,
            approvalStatus: capitalizefirstLetter(
              approvalRequest.approvalStatus
            )
          };
        })

        setApprovalRequests(modifiedDeviceApprovalRequests);

        setTotalCount(totalCount);

        const approvalRequestMapTemp = {};

        modifiedDeviceApprovalRequests.map((item) => {
          approvalRequestMapTemp[item.id] = { ...item };
        });

        setApprovalRequestMap(approvalRequestMapTemp);
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        });
      });
  }

  function onClickApprove(deviceId) {
    updateApprovalStatus(deviceId, DEVICE_APPROVAL_STATUS_KEYS.APPROVED);
  }

  function onClickDeny(deviceId) {
    updateApprovalStatus(deviceId, DEVICE_APPROVAL_STATUS_KEYS.DENIED);
  }

  function updateApprovalStatus(deviceId, approvalStatus) {
    updateApprovalStatusAPI(deviceId, approvalStatus)
      .then(() => {
        getApprovalRequests();
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: MESSAGES.GENERIC_EXCEPTION_MESSAGE,
          state: true
        });
      });
  }

  const getActionList = (id) => {
    let actions = [];
    if (
      approvalRequestMap[id] &&
      approvalRequestMap[id].approvalStatus !==
        DEVICE_APPROVAL_STATUS.APPROVED &&
      approvalRequestMap[id].approvalStatus !== DEVICE_APPROVAL_STATUS.DENIED
    ) {
      actions = [
        {
          label: "Approve",
          onClickHandler: onClickApprove,
          hasParameter: true
        },
        {
          label: "Deny",
          onClickHandler: onClickDeny,
          hasParameter: true
        }
      ];
    }
    return actions;
  };

  const chipsStyles = {
    approvalStatus: {
      Approved: {
        icon: (
          <CheckCircleIcon style={{ color: "#15803D", fontSize: "medium" }} />
        ),
        style: {
          color: "#15803D",
          backgroundColor: "#DCFCE7",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      Denied: {
        icon: <CancelIcon style={{ color: "#B91C1C", fontSize: "medium" }} />,
        style: {
          color: "#B91C1C",
          backgroundColor: "#FEE2DC",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      Requested: {
        icon: <PendingIcon style={{ color: "#7B341E", fontSize: "medium" }} />,
        style: {
          color: "#7B341E",
          backgroundColor: "#FEEBC8",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      }
    }
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "20px"
        }}
      >
        <PageTitle subheading="Manage Device Approvals" />
      </div>
      <div
        style={{
          marginBottom: "20px"
        }}
      >
        <MultiFilter
          filterValuesList={filterValuesList}
          filtersSelectedValue={filtersSelectedValue}
          setFiltersSelectedValue={setFiltersSelectedValue}
          showSearchBar={true}
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder="Search by ID"
        />
      </div>
      <TableComponent
        headings={[
          "id",
          "deviceName",
          "operatingSystem",
          "agentVersion",
          "approvalStatus"
        ]}
        data={approvalRequests}
        styles={{
          approvalStatus: "chip",
          table: { maxHeight: 450 }
        }}
        chipsStyles={chipsStyles}
        actions={[]}
        getActionListHandler={getActionList}
        columnWidths={{
          operatingSystem: 250,
          displayName: 130,
          deviceName: 130
        }}
        customColumnNames={{
          id: "Device ID"
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
    </div>
  );
}

export default DeviceApprovalRequests;
