import { useEffect, useState } from "react";

import { fetchDeviceGroupsByDeviceId } from "../../../API/devices";
import { deleteDeviceFromGroup } from "../../../API/group";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../utility/Table";
import CustomDialog from "../../utility/Dialog";
import { DIALOG_TYPES } from "../../../utils/Constants";
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip } from "@mui/material";
import {DESTINATION_ID_TO_PATH} from '../../../utils/Constants';

export default function DeviceGroupsAndPoliciesTab({ deviceId, setFeedback }) {
  const navigate = useNavigate();

  const [deviceGroups, setDeviceGroups] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState("");

  useEffect(() => {
    fetchDeviceGroupsByDeviceId(deviceId)
      .then((response) => {
        if (response.data) {
          const processedData = response.data.map((group) => ({
            ...group,
            policies: group.policies.map((policy) => ({
              ...policy,
              ...(policy.hasOwnProperty('isActive') && { style: getChipStyle(policy.isActive) })
            }))
          }));
          setDeviceGroups(processedData);
        }
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching the device.",
          state: true
        });
      });
  }, [deviceId]);
  const confirmRemove = (id) => {
    setSelectedGroupId(id);
    setOpenConfirmation(true);
  };

  const handleOpenGroup = (id) => {
    navigate("/device-groups", {
      state: {
        groupId: id
      }
    });
  };

  const handleOpenPolicy = (policy) => {
    const path = DESTINATION_ID_TO_PATH[policy.destinationId];
    const url = `${path}?policyId=${policy.id}`;
    window.open(url, '_blank');
  };

  const removeFromGroup = () => {
    deleteDeviceFromGroup(selectedGroupId, deviceId)
      .then(() => {
        setDeviceGroups(
          deviceGroups.filter(
            (deviceGroup) => deviceGroup.id != selectedGroupId
          )
        );
        setFeedback({
          severity: "success",
          message: "Device removed successfully.",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: error.data,
          state: true
        });
      })
      .finally(() => {
        setOpenConfirmation(false);
      });
  };
  const getChipStyle = (isActive) => {
    return isActive
      ? {
        color: "#15803D",
        backgroundColor: "#DCFCE7",
        borderRadius: "5px"
      }
      : {
        color: "#B91C1C",
        backgroundColor: "#FEE2DC",
        borderRadius: "5px"
      };
  };

  const renderChipOnToolTip = (label, style) => (
    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: 4 }}>
      <Chip
        label={label}
        variant="filled"
        style={{
          margin: "5px",
          height: "20px",
          ...style
        }}
      />
    </div>
  );

  const tooltipMessage = (<div>
    {renderChipOnToolTip("Active Policy", getChipStyle(true))}
    <br />
    {renderChipOnToolTip("Inactive Policy", getChipStyle(false))}
  </div>);

  return (
    <>
      <TableComponent
        headings={["id", "name", "policies"]}
        customColumnNames={{
          name: "Group Name",
          policies: "Policies Associated with Group"
        }}
        tootltipMessages={{
          policies: tooltipMessage,
        }}
        data={deviceGroups}
        options={{ hideIds: true, maxChips: 3 }}
        styles={{ name: "button", policies:"multi-chip", actionStyles: "buttons" }}
        actions={[
          {
            label: "Remove",
            onClickHandler: confirmRemove,
            hasParameter: true,
            color: "error",
            icon: <DeleteIcon />
          }
        ]}
        buttonActions={{
          name: { onClickHandler: handleOpenGroup }
        }}
        chipOnClickHandler= {handleOpenPolicy}
      />
      <CustomDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title="Are you sure you want to remove?"
        content="
        If you remove this device from the group, any policies applied to the device as part of the group association will also be removed."
        handleConfirm={removeFromGroup}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </>
  );
}
