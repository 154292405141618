export default function RegistryIcon() {
  return (
    <svg
      height="20"
      viewBox="0 0 520 520"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="_x37_63_x2C__Color_x2C__Editor_x2C__Registry_x2C__Square">
        <g>
          <path d="m105 405h-80c-5.523 0-10 4.478-10 10v80c0 5.522 4.477 10 10 10h80c5.523 0 10-4.478 10-10v-80c0-5.522-4.477-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m235 405h-80c-5.523 0-10 4.478-10 10v80c0 5.522 4.477 10 10 10h80c5.523 0 10-4.478 10-10v-80c0-5.522-4.477-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m365 405h-80c-5.522 0-10 4.478-10 10v80c0 5.522 4.478 10 10 10h80c5.522 0 10-4.478 10-10v-80c0-5.522-4.478-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m495 405h-80c-5.522 0-10 4.478-10 10v80c0 5.522 4.478 10 10 10h80c5.522 0 10-4.478 10-10v-80c0-5.522-4.478-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m105 275h-80c-5.523 0-10 4.478-10 10v80c0 5.522 4.477 10 10 10h80c5.523 0 10-4.478 10-10v-80c0-5.522-4.477-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m105 145h-80c-5.523 0-10 4.477-10 10v80c0 5.523 4.477 10 10 10h80c5.523 0 10-4.477 10-10v-80c0-5.523-4.477-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m235 275h-80c-5.523 0-10 4.478-10 10v80c0 5.522 4.477 10 10 10h80c5.523 0 10-4.478 10-10v-80c0-5.522-4.477-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m365 275h-80c-5.522 0-10 4.478-10 10v80c0 5.522 4.478 10 10 10h80c5.522 0 10-4.478 10-10v-80c0-5.522-4.478-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m235 145h-80c-5.523 0-10 4.477-10 10v80c0 5.523 4.477 10 10 10h80c5.523 0 10-4.477 10-10v-80c0-5.523-4.477-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m105 15h-80c-5.523 0-10 4.477-10 10v80c0 5.523 4.477 10 10 10h80c5.523 0 10-4.477 10-10v-80c0-5.523-4.477-10-10-10zm-10 80h-60v-60h60z" />
          <path d="m337.929 242.071c3.905 3.905 10.236 3.905 14.143 0l60-60c3.905-3.905 3.905-10.237 0-14.143l-60-60c-3.906-3.905-10.236-3.905-14.143 0l-60 60c-3.905 3.905-3.905 10.237 0 14.143zm7.071-112.929 45.857 45.858-45.857 45.858-45.857-45.858z" />
        </g>
      </g>
    </svg>
  );
}
