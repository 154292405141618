import { useState, useEffect } from "react";
import { fetchAllWithFilter } from "../../API/registryConfiguration";
import RegistryEditorForm from "./RegistryEditorForm";
import Pagination from "../utility/Pagination";
import TableComponent from "../utility/Table";
import SearchBar from "../utility/SearchBar";
import CreateButton from "../utility/CreateButton";
import PageTitle from "../utility/PageTitle";
import { Add } from "@mui/icons-material";
import { REGISTRY_ACTION } from "../../utils/Constants";
import { Typography } from "@mui/material";
import SummaryDetails from "./SummaryDetail";
import CustomAlert from "../../Infobar";

export default function RegistryConfigurationReport({ setFeedback }) {
  const [open, setOpen] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedId, setSelectedId] = useState("");

  const KEY_LABEL_MAP = {
    headerKey: "Header Key",
    subKey: "Sub Key",
    dataType: "Data Type",
    valueName: "Value Name",
    valueData: "Value Data",
    action: "Action"
  };
  const processConfigurationObject = (object) => {
    return (
      <div>
        {Object.entries(object || {})
          .filter(([key, value]) => value)
          .map(([key, value], index) => (
            <Typography key={index}>
              <strong>{KEY_LABEL_MAP[key] || key}</strong>: {value}
            </Typography>
          ))}
      </div>
    );
  };

  useEffect(() => {
    fetchAllWithFilter(pageSize, pageNumber, searchText)
      .then(({ data }) => {
        const processData = data.data.map((item) => {
          const { pending, failed, success, configuration, ...rest } = item;
          const totalTargets = pending + failed + success;
          const summaryString = [
            `Total Devices (${totalTargets})`,
            failed > 0 ? `Failed (${failed})` : null,
            success > 0 ? `Success (${success})` : null,
            pending > 0 ? `Pending (${pending})` : null
          ]
            .filter(Boolean)
            .join(", ");

          return {
            ...rest,
            configuration: processConfigurationObject(configuration),
            action: REGISTRY_ACTION[item["action"]].label,
            summary: summaryString
          };
        });
        setData(processData);
        setTotalCount(data.totalCount);
      })
      .catch((error) => {});
  }, [fetchCount, searchText, pageNumber, pageSize]);

  const handleView = (id) => {
    setSelectedId(id);
  };

  return (
    <div>
      <PageTitle />
      <CustomAlert
        type="info"
        text="Registry Configuration will be applied during device startup/refresh."
        styles={{ margin: "12px 0" }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by name"}
        />
        <CreateButton
          label={"Create Configuration"}
          startIcon={<Add />}
          onClickHandler={() => setOpen(true)}
        />
      </div>
      <TableComponent
        headings={[
          "id",
          "name",
          "action",
          "creationDttm",
          "summary",
          "configuration"
        ]}
        data={data}
        customColumnNames={{
          name: "Name",
          creationDttm: "Created At",
          summary: "Execution status"
        }}
        actions={[
          {
            label: "View details",
            onClickHandler: handleView,
            hasParameter: true
          }
        ]}
        options={{ hideIds: true }}
        styles={{
          table: { maxHeight: 380 },
          creationDttm: "date-time",
          configuration: "tooltip"
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <RegistryEditorForm
        setFeedback={setFeedback}
        open={open}
        setOpen={setOpen}
        setFetchCount={setFetchCount}
      />
      {selectedId && (
        <SummaryDetails
          setFeedback={setFeedback}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          registryInfo={data?.find((item) => item.id == selectedId)}
          processConfigurationObject={processConfigurationObject}
        />
      )}
    </div>
  );
}
