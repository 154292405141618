import React, { useEffect, useState } from "react";
import {
  IconButton,
  Toolbar,
  Divider,
  Typography,
  List,
  ListItem
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import "./DeviceDrawer.css";
import { getDeviceEvents } from "../../../API/deviceActivity";
import { formatSeconds, getTimeFromDate } from "../../../utils/Helpers";
import { AccountBox } from "@mui/icons-material";

function DeviceDrawer({
  open,
  onClose,
  selectedDevice,
  startDttm,
  endDttm,
  setFeedback
}) {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (open) {
      getDeviceEvents(selectedDevice.deviceId, startDttm, endDttm)
        .then(({ data }) => {
          setEvents(data);
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "Error in fetching activity.",
            state: true
          });
        });
    }
  }, [open, selectedDevice]);

  return (
    <div
      className={`drawer-form-wrapper ${
        open ? "drawer-form-open" : "drawer-form-closed"
      }`}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={onClose} sx={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        <Typography variant="h5" component="div">
          <h5>{selectedDevice?.deviceName || "No Device Selected"}</h5>
        </Typography>

        <div className="device-info">
          <Typography variant="body2" className="device-info-sign-in">
            Sign In
          </Typography>
          <Typography variant="body2" className="device-info-sign-out">
            Sign Out
          </Typography>
        </div>

        <Divider sx={{ opacity: 1, margin: "10px 0" }} />

        {events.length === 0 ? (
          <Typography variant="subtitle1" component="div" align="center">
            No items found
          </Typography>
        ) : (
          events.reverse().map((event) => {
            return (
              <React.Fragment key={event.date}>
                <Typography
                  variant="subtitle2"
                  component="div"
                  className="device-session"
                >
                  <span>
                    <b>{event.date}</b>
                  </span>
                  <span>
                    <b>{formatSeconds(event.duration)}</b>
                  </span>
                </Typography>
                <List>
                  {event.sessions.reverse().map((session, idx) => {
                    return (
                      <ListItem key={idx} className="session-list-item">
                        {session.username && (
                          <div className="session-list-username">
                            <AccountBox sx={{marginRight:"4px"}}/>
                            {session.username}
                            <Divider/>  
                          </div>
                        )}
                        <div className="session-info">
                          <div className="session-sign-in">
                            <LockOpenOutlinedIcon />
                            <span>
                              {session.signInDttm
                                ? getTimeFromDate(session.signInDttm)
                                : "N/A"}
                            </span>
                          </div>
                          <div className="session-divider">
                            <Divider
                              orientation="horizontal"
                              flexItem
                              sx={{
                                height: "3px",
                                borderStyle: "dashed",
                                margin: "12px 10px",
                                width: "100%",
                                borderColor: "#000",
                                position: "absolute"
                              }}
                            />
                            <Typography
                              variant="caption"
                              component="div"
                              className="session-hours"
                            >
                              {formatSeconds(session.duration)}
                            </Typography>
                          </div>
                          <div className="session-sign-out">
                            <span>
                              {session.signOutDttm
                                ? getTimeFromDate(session.signOutDttm)
                                : "Still Signed In"}
                            </span>
                            <LockOutlinedIcon />
                          </div>
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
                <Divider sx={{ opacity: 1, margin: "10px 0" }} />
              </React.Fragment>
            );
          })
        )}
      </div>
    </div>
  );
}

export default DeviceDrawer;
