import { properties } from "../properties";
import api from "../services/api";

function getMFAReports(
  pageSize,
  pageNumber,
  searchText,
  startTime,
  endTime
) {
  let url = `${properties.reportingService}/mfa-reports?`;
  if (startTime && startTime.isValid()) {
    url += `startDttm=${new Date(startTime).toISOString()}&`;
  }
  if (endTime && endTime.isValid())
    url += `endDttm=${new Date(endTime).toISOString()}&`;
  url += `pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${searchText}`;
  return api.get(url);
}


export {
  getMFAReports
};
