import { useState, useEffect } from "react";
import TableComponent from "../utility/Table";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import {
  deleteMultiplePolicies,
  deletePolicy,
  fetchAllPoliciesByDestination,
  updateIsEnabledForPolicy
} from "../../API/policy";
import PolicyForm from "./PolicyForm";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PageTitle from "../utility/PageTitle";
import SearchBar from "../utility/SearchBar";
import CustomDialog from "../utility/Dialog";
import AddIcon from "@mui/icons-material/Add";
import EmailPolicyForm from "./email_policy/EmailPolicyForm";
import Pagination from "../utility/Pagination";
import HttpPolicyForm from "./http_policy/HttpPolicyForm";
import BulkAction from "../utility/BulkAction";
import { DIALOG_TYPES } from "../../utils/Constants";
import CustomAlert from "../../Infobar";
import CreateButton from "../utility/CreateButton";
import { PRIVILEGES } from "../../utils/Privileges";
import { CheckPermission } from "../utility/CheckPermission";

const label = { inputProps: { "aria-label": "Switch demo" } };

function PolicyList({ setFeedback }) {
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [data, setData] = useState(null);
  const [fetchCount, setFetchCount] = useState(0);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [searchText, setSearchText] = useState(null);
  const user = useSelector(({ auth }) => auth.currentUser);
  const location = useLocation();
  const [selectedRecordId, setSelectedRecordId] = useState([]);
  const [subheading, setSubheading] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [bulkAction, setBulkAction] = useState("");
  const [resetComponent, setResetComponent] = useState(false);

  const params = new URLSearchParams(location.search);
  const policyId = params.get('policyId');
  useEffect(() => {
    if (policyId !== null) {
      handleView(policyId);
    }
  }, [policyId]);

  useEffect(() => {
    const policyMap = {
      "/usb/policy": { id: 1 },
      "/email-policy": {
        id: 2,
        subheading:
          "Implement Email Policy to Prevent Sending Sensitive Data via Emails"
      },
      "/http/policy": { id: 3 },
      "/clipboard-policy": { id: 4 },
      "/screenshot-policy": { id: 5 },
      "/desktop-app/policy": { id: 6 },
      "/ethernet-policy": {
        id: 7,
        subheading: "Implement Ethernet Policy to Restrict Ethernet Connections"
      },
      "/printer/policy": { id: 8 }
    };

    const { id: destinationId = 1, subheading = "" } =
      policyMap[location.pathname] || {};

    if (subheading) setSubheading(subheading);

    fetchAllPoliciesByDestination(
      destinationId,
      searchText,
      pageNumber,
      pageSize
    )
      .then((response) => {
        setTotalCount(response.data.totalCount);
        setData(response.data.policies);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching policies.",
          state: true
        });
      });
  }, [fetchCount, location.pathname, searchText, pageNumber, pageSize]);

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedId(null);
  };

  const handleConfirmBulkDelete = () => {
    deleteMultiplePolicies(selectedRecordId)
      .then(() => {
        setFeedback({
          severity: "success",
          message: "Policies deleted successfully",
          state: true
        });
        setFetchCount((fetchCount) => fetchCount + 1);
        setSelectedRecordId([]);
      })
      .catch(() => {
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
      });
  };

  const handleDelete = (id) => {
    setSelectedId(id);
    setIsDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    deletePolicy(selectedId)
      .then((response) => {
        setData((prevData) =>
          prevData.filter((policy) => policy.id !== selectedId)
        );
        setFeedback({
          severity: "success",
          message: "Policy deleted successfully",
          state: true
        });
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while deleting the policy.",
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  function handleView(id){
    setOpen(true);
    setSelectedId(id);
  };

  function handleUpdateActiveStatus(id, event) {
    const jsonPatch = {
      op: "replace",
      path: "/isActive",
      value: event.target.checked
    };
    const isChecked = event.target.checked;
    let message = "";
    if (isChecked) message = "Activated";
    else message = "Deactivated";
    updateIsEnabledForPolicy(id, jsonPatch)
      .then((response) => {
        setData(
          data.map((classification) => {
            if (classification.id === id) classification.isActive = isChecked;
            return classification;
          })
        );
        setFeedback({
          severity: "success",
          message: `Policy ${message}`,
          state: true
        });
      })
      .catch((error) =>
        setFeedback({
          severity: "error",
          message: "Something went wrong",
          state: true
        })
      );
  }

  const checkIsPolicyEnabled = (id) => {
    return data.find((policy) => policy.id === id).isActive;
  };

  const handleBulkActionChange = (action) => {
    setBulkAction(action);
    if (action === "Delete") {
      setIsDeleteConfirmationOpen(true);
    }
  };

  const headings = [
    "id",
    "name",
    ...(location.pathname == "/http/policy" ? ["risk"] : ["action"]),
    ...(CheckPermission({ requiredPrivileges: [PRIVILEGES.EDIT_POLICY.name] })
      ? ["IsEnabled"]
      : [])
  ];

  const alertMessages = {
    "/http/policy": "Create HTTP Policy to manage and control web traffic.",
    "/usb/policy": "Set policies to control and secure USB device usage.",
    "/desktop-app/policy": "Block specific apps to prevent unauthorized usage.",
    "/printer/policy":
      "Implement Printer Policy to control and manage printer access."
  };

  const getAlertMessage = () => alertMessages[location.pathname] || "";

  return (
    <div>
      {!location.pathname.includes("/ethernet-policy") &&
        !location.pathname.includes("/email-policy") && (
          <CustomAlert
            type="info"
            text={getAlertMessage()}
            styles={{ marginBottom: "15px" }}
          />
        )}

      {location.pathname !== "/http/policy" &&
      location.pathname !== "/usb/policy" &&
      location.pathname !== "/printer/policy" &&
      location.pathname !== "/desktop-app/policy" ? (
        <div className="main-title-heading-container">
          <PageTitle subheading={subheading} />
        </div>
      ) : null}

      {location.pathname.includes("/email-policy") && (
        <CustomAlert
          type="info"
          text={
            <>
              Before implementing the Email DLP Policy, you must first configure
              your email provider to integrate with the Email DLP Server. This
              ensures proper monitoring and protection against data loss. Refer
              to the following guides{" "}
              <a
                href="https://www.miniorange.com/dlp/solutions/setup-guide-for-google-workspace"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Workspace Setup
              </a>{" "}
              and{" "}
              <a
                href="https://www.miniorange.com/dlp/solutions/configure-email-dlp-for-outlook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Office 365 Setup
              </a>
              .
            </>
          }
          styles={{ marginBottom: "15px", textAlign: "justify" }}
        />
      )}
      <div style={{ float: "right", marginBottom: "15px" }}>
        <CreateButton
          label={"Add Policy"}
          startIcon={<AddIcon />}
          onClickHandler={handleClickOpen}
          requiredPrivileges={[PRIVILEGES.CREATE_POLICY.name]}
        />
      </div>
      {/* <InfoBox infoText="Policies are the entities which are applied on different user groups" /> */}
      <div style={{ display: "flex" }}>
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Policy Name"}
        />
      </div>
      {selectedRecordId.length === 0 ? null : (
        <div style={{ textAlign: "left", paddingTop: 10 }}>
          <BulkAction
            bulkActions={["Delete"]}
            selectedRecordId={selectedRecordId}
            setFeedback={setFeedback}
            fetchAndFormatFields={() => {}}
            fieldPlaceholder={""}
            fieldLabel={""}
            onSearch={false}
            setCurrentFields={() => {}}
            handleBulkActionChange={handleBulkActionChange}
            resetComponent={resetComponent}
            setResetComponent={setResetComponent}
          />
        </div>
      )}

      <TableComponent
        headings={headings}
        data={data}
        options={{ hideIds: true }}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleView,
            hasParameter: true,
            requiredPrivileges: [PRIVILEGES.EDIT_POLICY.name]
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true,
            requiredPrivileges: [PRIVILEGES.DELETE_POLICY.name]
          }
        ]}
        customColumnNames={{
          action: "Incident Action",
          IsEnabled: "Policy Enabled"
        }}
        styles={{
          IsEnabled: "switch",
          table: { maxHeight: "550px" }
        }}
        toggleAction={{
          toggleStatusChecker: checkIsPolicyEnabled,
          onChangeHandler: handleUpdateActiveStatus
        }}
        multiselect={{
          selectedRecordId,
          setSelectedRecordId
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      {location.pathname === "/email-policy" ? (
        <EmailPolicyForm
          open={open}
          setOpen={setOpen}
          setFeedback={setFeedback}
          selectedPolicyId={selectedId}
          setFetchCount={setFetchCount}
        />
      ) : location.pathname === "/http/policy" ? (
        <HttpPolicyForm
          open={open}
          setOpen={setOpen}
          setFeedback={setFeedback}
          selectedPolicyId={selectedId}
          setFetchCount={setFetchCount}
        />
      ) : (
        <PolicyForm
          open={open}
          setOpen={setOpen}
          setFeedback={setFeedback}
          selectedPolicyId={selectedId}
          setFetchCount={setFetchCount}
        />
      )}
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={
          bulkAction === "Delete"
            ? handleConfirmBulkDelete
            : handleConfirmDelete
        }
        handleCleanup={() => {
          setResetComponent(true);
        }}
        content={
          bulkAction === "Delete"
            ? `Are you sure you want to delete the selected ${selectedRecordId.length} policies?`
            : "Are you sure you want to delete this policy?"
        }
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default PolicyList;
