import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Fade from "@mui/material/Fade"; 
import Box from "@mui/material/Box";

const CustomTooltip = ({
  title,
  arrow = true, 
  placement = "right",
  TransitionComponent = Fade,
  TransitionProps = { timeout: 600 },
  style = {},
  iconButtonStyle = {},
  icon = <InfoOutlinedIcon sx={{ width: "20px" }} />,
}) => {
  return (
    <Tooltip
      title={
        <Box style={{ fontSize: "14px", fontFamily: "DM Sans, sans-serif", ...style }}>
          {title}
        </Box>
      }
      arrow={arrow}
      placement={placement}
      TransitionComponent={TransitionComponent}
      TransitionProps={TransitionProps}
    >
      <IconButton sx={{ marginTop: "-4px", marginLeft: "-4px", ...iconButtonStyle }}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default CustomTooltip;
