import GroupsIcon from "../components/assets/icons/GroupsIcon";
import DashboardIcon from "../components/assets/icons/DashboardIcon";
import UserIcon from "../components/assets/icons/UserIcon";
import DesktopWithMobileIcon from "../components/assets/icons/DesktopWithMobileIcon";
import ClassificationIcon from "../components/assets/icons/ClassificationIcon";
import RuleDictionaryIcon from "../components/assets/icons/RuleDictionaryIcon";
import ApprovalIcon from "../components/assets/icons/ApprovalIcon";
import PasswordConfigurationIcon from "../components/assets/icons/PasswordConfigurationIcon";
import UsbIcon from "../components/assets/icons/UsbIcon";
import EmailIcon from "../components/assets/icons/EmailIcon";
import ClipboardIcon from "../components/assets/icons/ClipboardIcon";
import ScreenshotIcon from "../components/assets/icons/ScreenShotIcon";
import SiteBlocking from "../components/assets/icons/SiteBlockingIcon";
import EmailReports from "../components/assets/icons/EmailReportsIcon";
import DeviceReports from "../components/assets/icons/DeviceReportsIcon";
import DeviceControl from "../components/assets/icons/DeviceControlIcon";
import PolicyIcon from "../components/assets/icons/PolicyIcon";
import CategoriesIcon from "../components/assets/icons/CategoriesIcon";
import ActiveDirectoryIcon from "../components/assets/icons/ActiveDirectoryIcon";
import ApplicationIcon from "../components/assets/icons/ApplicationIcon";
import ConfigurationIcon from "../components/assets/icons/ConfigurationIcon";
import { FEATURES } from "./Constants";
import NotifyIcon from "../components/assets/icons/NotifyIcon";
import UsbReportIcon from "../components/assets/icons/UsbReportIcon";
import HttpReportIcon from "../components/assets/icons/HttpReportIcon";
import DesktopAppIcon from "../components/assets/icons/DesktopAppIcon";
import EthernetIcon from "../components/assets/icons/EthernetIcon";
import GlobeConfgIcon from "../components/assets/icons/GlobeConfgIcon";
import { PRIVILEGES } from "./Privileges";
import ReportIcon from "../components/assets/icons/ReportIcon";
import PrinterIcon from "../components/assets/icons/PrinterIcon";
import DeviceMonitoringIcon from "../components/assets/icons/DeviceMonitoringIcon";
import RegistryIcon from "../components/assets/icons/RegistryIcon";
import { LockOpen } from "@mui/icons-material";

const drawerItems = [
  {
    itemName: "Dashboard",
    itemLink: "/dashboard",
    ItemIcon: DashboardIcon,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "Manage Devices",
    ItemIcon: DeviceControl,
    subItems: [
      {
        itemName: "Devices",
        itemLink: "/view-devices",
        ItemIcon: DesktopWithMobileIcon,
        featureName: FEATURES.DEVICES,
        requiredPrivileges: [PRIVILEGES.VIEW_DEVICE_LIST.name]
      },
      {
        itemName: "Device Groups",
        itemLink: "/device-groups",
        ItemIcon: GroupsIcon,
        featureName: FEATURES.DEVICES,
        requiredPrivileges: [PRIVILEGES.VIEW_DEVICE_GROUPS.name]
      },
      {
        itemName: "Onboarding Requests",
        itemLink: "/device-approval-requests",
        featureName: FEATURES.DEVICES,
        requiredPrivileges: [PRIVILEGES.MANAGE_DEVICE_APPROVALS.name]
      },
    ],
    requiredPrivileges: [
      PRIVILEGES.VIEW_DEVICE_LIST.name,
      PRIVILEGES.VIEW_DEVICE_GROUPS.name,
      PRIVILEGES.MANAGE_DEVICE_APPROVALS.name
    ]
  },
  {
    itemName: "Device Policies",
    ItemIcon: PolicyIcon,
    subItems: [
      {
        itemName: "USB",
        itemLink: "/usb",
        ItemIcon: UsbIcon,
        featureName: FEATURES.USB,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      },
      {
        itemName: "Printer",
        itemLink: "/printer",
        ItemIcon: UsbIcon,
        featureName: FEATURES.USB,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      },
      {
        itemName: "Ethernet",
        itemLink: "/ethernet-policy",
        ItemIcon: UsbIcon,
        featureName: FEATURES.ETHERNET,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      },
      // {
      //   itemName: "Clipboard",
      //   itemLink: "/clipboard-policy",
      //   ItemIcon: ClipboardIcon,
      //   featureName: FEATURES.CLIPBOARD,
      //   requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      // },
      // {
      //   itemName: "Screenshot",
      //   itemLink: "/screenshot-policy",
      //   ItemIcon: ScreenshotIcon,
      //   featureName: FEATURES.SCREENSHOT,
      //   requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      // },
      {
        itemName: "Websites",
        itemLink: "/http",
        ItemIcon: SiteBlocking,
        featureName: FEATURES.SITE_BLOCKING,
        requiredPrivileges: [
          PRIVILEGES.VIEW_POLICY.name,
          PRIVILEGES.VIEW_DOMAIN_AND_CATEGORY.name
        ]
      },
      {
        itemName: "Desktop apps",
        itemLink: "/desktop-app",
        ItemIcon: PolicyIcon,
        featureName: FEATURES.APP_BLOCKING,
        requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
      },
      {
        itemName: "Registry Editor",
        itemLink: "/registry-editor",
        ItemIcon: RegistryIcon,
        featureName: FEATURES.GENERAL,
        requiredPrivileges: [PRIVILEGES.CONFIGURE_REGISTRY.name]
      }
    ],
    requiredPrivileges: [
      PRIVILEGES.VIEW_POLICY.name,
      PRIVILEGES.VIEW_DOMAIN_AND_CATEGORY.name,
      PRIVILEGES.VIEW_APP.name,
      PRIVILEGES.VIEW_APP_CATEGORY.name
    ]
  },
  {
    itemName: "Manage Email Users",
    ItemIcon: UserIcon,
    subItems: [
      {
        itemName: "Email Users",
        itemLink: "/view-users",
        ItemIcon: UserIcon,
        featureName: FEATURES.USERS,
        requiredPrivileges: [PRIVILEGES.VIEW_USER_LIST.name]
      },
      {
        itemName: "Email User Groups",
        itemLink: "/user-groups",
        ItemIcon: DesktopWithMobileIcon,
        featureName: FEATURES.USERS,
        requiredPrivileges: [PRIVILEGES.VIEW_USER_GROUPS.name]
      }
    ],
    requiredPrivileges: [
      PRIVILEGES.VIEW_USER_LIST.name,
      PRIVILEGES.VIEW_USER_GROUPS.name
    ]
  },
  {
    itemName: "Email Policy",
    itemLink: "/email-policy",
    ItemIcon: EmailIcon,
    featureName: FEATURES.EMAIL_BLOCKING,
    requiredPrivileges: [PRIVILEGES.VIEW_POLICY.name]
  },
  {
    itemName: "Notifications",
    itemLink: "/policy-breach-alert",
    ItemIcon: NotifyIcon,
    featureName: FEATURES.GENERAL,
    requiredPrivileges: [PRIVILEGES.VIEW_NOTIFICATION.name]
  },
  {
    itemName: "Data Classification",
    ItemIcon: ClassificationIcon,
    subItems: [
      {
        itemName: "Classification",
        itemLink: "/classification-list",
        ItemIcon: ClassificationIcon,
        featureName: FEATURES.CLASSIFICATION,
        requiredPrivileges: [PRIVILEGES.VIEW_CLASSIFICATION.name]
      },
      {
        itemName: "Rule Dictionary",
        itemLink: "/rule-list",
        ItemIcon: RuleDictionaryIcon,
        featureName: FEATURES.CLASSIFICATION,
        requiredPrivileges: [PRIVILEGES.VIEW_RULE.name]
      }
    ],
    requiredPrivileges: [
      PRIVILEGES.VIEW_CLASSIFICATION.name,
      PRIVILEGES.VIEW_RULE.name
    ]
  },
  {
    itemName: "Approval Requests",
    itemLink: "/approval-requests",
    ItemIcon: ApprovalIcon,
    featureName: FEATURES.GENERAL,
    requiredPrivileges: [PRIVILEGES.VIEW_APPROVAL.name]
  },
  {
    itemName: "Organizational Domains",
    itemLink: "/organizational-domains",
    ItemIcon: GlobeConfgIcon,
    featureName: FEATURES.GENERAL,
    requiredPrivileges: [PRIVILEGES.VIEW_ORGANIZATIONAL_DOMAIN.name]
  },
  {
    itemName: "Manage Roles & Privileged Users",
    itemLink: "/manage",
    ItemIcon: GroupsIcon,
    featureName: FEATURES.MANAGE_ROLES_AND_USERS
  }
];

const reportingDrawerItems = [
  {
    itemName: "USB Reporting",
    itemLink: "/reporting/usb-reporting",
    ItemIcon: UsbReportIcon,
    featureName: FEATURES.GENERAL,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    itemName: "Printer Reporting",
    itemLink: "/reporting/printer-reporting",
    ItemIcon: PrinterIcon,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "HTTP Reporting",
    itemLink: "/reporting/http-reporting",
    ItemIcon: HttpReportIcon,
    featureName: FEATURES.GENERAL,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    itemName: "Desktop Apps Reporting",
    itemLink: "/reporting/desktop-apps-reporting",
    ItemIcon: DesktopAppIcon,
    featureName: FEATURES.GENERAL,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    itemName: "MFA Reporting",
    itemLink: "/reporting/mfa-reporting",
    ItemIcon: EthernetIcon,
    featureName: FEATURES.GENERAL,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    itemName: "Email Reports",
    itemLink: "/reporting/email-reports",
    ItemIcon: EmailReports,
    featureName: FEATURES.EMAIL_BLOCKING,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    itemName: "MFA Reports",
    itemLink: "/reporting/mfa-reports",
    ItemIcon: LockOpen,
    featureName: FEATURES.GENERAL,
    requiredPrivileges: [PRIVILEGES.VIEW_REPORTS.name]
  },
  {
    itemName: "Device Monitoring",
    itemLink: "/reporting/device-monitoring",
    ItemIcon: DeviceMonitoringIcon,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "Agent Status Report",
    itemLink: "/reporting/agent-status-report",
    ItemIcon: DeviceControl,
    featureName: FEATURES.GENERAL
  },
  {
    itemName: "Bulk Operation Reports",
    itemLink: "/reporting/bulk-uploads",
    ItemIcon: ReportIcon,
    featureName: FEATURES.GENERAL
  }
];

const routeHeadings = {
  "/view-users": "Users",
  "/groups": "Groups",
  "/view-devices": "Devices",
  "/device-approval-requests": "Device Approvals",
  "/classification-list": "Classification",
  "/rule-list": "Rule Dictionary",
  "/policy-list": "Policy",
  "/dashboard": "Dashboard",
  "/password-configuration": "Password Configuration",
  "/usb": "USB Policy",
  "/usb/policy": "USB Policy",
  "/email-policy": "Email Policy",
  "/screenshot-policy": "Screenshot Policy",
  "/clipboard-policy": "Clipboard Policy",
  "/printer": "Printer Policy",
  "/printer/policy": "Printer Policy",
  "/http": "Websites",
  "/http/policy": "Websites",
  "/desktop-app": "Desktop Applications",
  "/desktop-app/category": "Application Categories",
  "/desktop-app/policy": "Application Policy",
  "/device-reports": "Device Reports",
  "/my-account": "Profile",
  "/user-groups": "User Groups",
  "/device-groups": "Device Groups",
  "/app-categories": "App Blocking Categories",
  "/approval-requests": "Policy Relaxation",
  "/ad-details": "Active Directory",
  "/settings": "Settings",
  "/smtp-settings": "SMTP Configurations",
  "/settings/ad": "Active Directory Configuration",
  "/settings/smtp": "SMTP Configuration",
  "/settings/password-policy": "Password policy Configuration",
  "/customization/email-templates": "Email Template Configuration",
  "/customization/email-templates/edit": "Email Template Configuration",
  "/ethernet-policy": "Ethernet Policy",
  "/organizational-domains": "Organizational Domains",
  "/policy-breach-alert": "Policy Breach Notifications",
  "/reporting/bulk-uploads": "Bulk Operation Reports",
  "/reporting/usb-reporting": "USB Reporting",
  "/reporting/printer-reporting": "Printer Reporting",
  "/reporting/http-reporting": "HTTP Reporting",
  "/reporting/desktop-apps-reporting": "Desktop Apps Reporting",
  "/reporting/mfa-reporting": "MFA Reporting",
  "/reporting/email-reports": "Email Reporting",
  "/reporting/mfa-reports": "Multi-Factor Authentiction Reports",
  "/reporting/device-monitoring": "Device Monitoring",
  "/reporting/agent-status-report": "Agent Status Report",
  "/manage": "Manage Roles & Privileged Users",
  "/manage/role": "Manage Roles & Privileged Users",
  "/manage/user": "Manage Roles & Privileged Users",
  "/customization": "Custom Branding Configuration",
  "/customization/basic": "Custom Branding Configuration",
  "/registry-editor":"Registry Configurations"
};

export { drawerItems, routeHeadings, reportingDrawerItems };
