import React, { useState, useEffect } from "react";
import { deleteRole, getAllRolesWithFilter } from "../../API/manageRoles";
import TableComponent from "../utility/Table";
import RoleForm from "./RoleForm";
import SearchBar from "../utility/SearchBar";
import { DIALOG_TYPES } from "../../utils/Constants";
import CustomDialog from "../utility/Dialog";
import Pagination from "../utility/Pagination";
import Add from "@mui/icons-material/Add";
import CreateButton from "../utility/CreateButton";
import { PRIVILEGES } from "../../utils/Privileges";

function RoleList({ setFeedback }) {
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [fetchCount, setFetchCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [pageSize, setPageSize] = useState(10);
  
  const formatPrivileges = (data) => {    
    return data.map((item) => ({
      ...item,
      privileges: item.privileges.map((privilege) => ({
        ...privilege, 
        name: PRIVILEGES[privilege.name]?.title, 
      })),
    }));
  };

  useEffect(() => {
    getAllRolesWithFilter(pageSize, pageNumber, searchText)
      .then(({ data }) => {
        setRoles(formatPrivileges(data.data));
        setTotalCount(data.totalCount);
      })
      .catch((error) => {
        setFeedback({
          severity: "error",
          message: "There is an issue while fetching Roles!",
          state: true
        });
      });
  }, [fetchCount, pageNumber, searchText, pageSize]);

  const handleDelete = (id) => {
    setIsDeleteConfirmationOpen(true);
    setSelectedId(id);
  };

  const handleConfirmDelete = () => {
    deleteRole(selectedId)
      .then(function (response) {
        setRoles(roles.filter((role) => role.id !== selectedId));
        setFeedback({
          severity: "success",
          message: "Role deleted successfully",
          state: true
        });
        setFetchCount((prev) => prev + 1);
      })
      .catch(function (error) {
        setFeedback({
          severity: "error",
          message: "Unable to delete the role because it is currently assigned to one or more users.",
          state: true
        });
      })
      .finally(() => {
        setIsDeleteConfirmationOpen(false);
        setSelectedId("");
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedId(null);
  };

  const handleView = (id) => {
    setOpen(true);
    setSelectedId(id);
  };

  return (
    <div>
      <div className="main-title-heading-container">
        <SearchBar
          setSearchText={setSearchText}
          searchText={searchText}
          placeholder={"Search by Name"}
        />
        <CreateButton
          label={"Add Role"}
          onClickHandler={handleClickOpen}
          startIcon={<Add />}
        />
      </div>

      <TableComponent
        headings={["id", "name", "description", "privileges"]}
        data={roles}
        options={{ hideIds: true, maxChips: 3 }}
        customColumnNames={{
          name: "Role"
        }}
        actions={[
          {
            label: "Edit",
            onClickHandler: handleView,
            hasParameter: true
          },
          {
            label: "Delete",
            onClickHandler: handleDelete,
            hasParameter: true
          }
        ]}
        styles={{
          privileges: "multi-chip",
          table: { maxHeight: 425 }
        }}
      />
      {totalCount != 0 ? (
        <div className="pagination">
          <Pagination
            totalCount={totalCount}
            page={pageNumber}
            setPage={setPageNumber}
            rowsPerPage={pageSize}
            setRowsPerPage={setPageSize}
          />
        </div>
      ) : null}
      <RoleForm
        open={open}
        setOpen={setOpen}
        setFeedback={setFeedback}
        selectedRoleId={selectedId}
        setFetchCount={setFetchCount}
      />
      <CustomDialog
        open={isDeleteConfirmationOpen}
        title="Confirm Deletion"
        content="Are you sure you want to delete?"
        setOpen={setIsDeleteConfirmationOpen}
        handleConfirm={handleConfirmDelete}
        dialogType={DIALOG_TYPES.CONFIRM}
      />
    </div>
  );
}

export default RoleList;
