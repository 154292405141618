import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Toolbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { fetchSummaryDetail } from "../../API/registryConfiguration";
import TableComponent from "../utility/Table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import { formatDateTimeString } from "../../utils/Helpers";

function SummaryDetails({
  selectedId,
  setSelectedId,
  setFeedback,
  processConfigurationObject,
  registryInfo
}) {
  const [records, setRecords] = useState([]);
  const chipsStyles = {
    status: {
      PENDING: {
        icon: <PendingIcon style={{ color: "#7B341E", fontSize: "medium" }} />,
        style: {
          color: "#7B341E",
          backgroundColor: "#FEEBC8",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      SUCCESS: {
        icon: (
          <CheckCircleIcon style={{ color: "#15803D", fontSize: "medium" }} />
        ),
        style: {
          color: "#15803D",
          backgroundColor: "#DCFCE7",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      },
      FAILED: {
        icon: <CancelIcon style={{ color: "#B91C1C", fontSize: "medium" }} />,
        style: {
          color: "#B91C1C",
          backgroundColor: "#FEE2DC",
          borderRadius: "5px",
          fontWeight: "bold"
        }
      }
    }
  };

  useEffect(() => {
    if (selectedId) {
      fetchSummaryDetail(selectedId)
        .then(({ data }) => {
          setRecords(
            data.map((item) => ({
              ...item,
              prevConfiguration: item.prevConfiguration
                ? processConfigurationObject(item.prevConfiguration)
                : "Not available"
            }))
          );
        })
        .catch((error) => {
          setFeedback({
            severity: "error",
            message: "Error in fetching summary details.",
            state: true
          });
        });
    }
  }, [selectedId]);

  return (
    <div
      className={`drawer-form-wrapper ${
        selectedId ? "drawer-form-open" : "drawer-form-closed"
      }`}
      style={{ width: "50%" }}
    >
      <Toolbar />
      <br />
      <div className="close-button">
        <IconButton onClick={() => setSelectedId("")}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="drawer-form-container">
        <h5>Execution Details</h5>
        <Divider />
        <br />
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={6}>
            <div>
              <strong>Name:</strong> {registryInfo?.name}
            </div>
            <div>
              <strong>Action:</strong> {registryInfo?.action}
            </div>
            <div>
              <strong>Created At:</strong>{" "}
              {formatDateTimeString(registryInfo?.creationDttm + "Z")}
            </div>
          </Grid>
          <Grid item xs={6}>
            {registryInfo?.configuration}
          </Grid>
        </Grid>
        <br />
        <TableComponent
          headings={["deviceName", "status", "message", "prevConfiguration"]}
          data={records}
          customColumnNames={{
            deviceName: "Device",
            prevConfiguration: "Previous Configuration"
          }}
          styles={{
            prevConfiguration: "tooltip",
            status: "chip"
          }}
          chipsStyles={chipsStyles}
        />
      </div>
    </div>
  );
}

export default SummaryDetails;
