import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getIncidentsPerDay } from "../../API/reporting";
import { useState } from "react";

ChartJS.register(CategoryScale, PointElement, LineElement, Tooltip);


export default function LineChartWrapper({totalDays}) {
  const [lineChartValuesAndLabels, setLineChartValuesAndLabels] = useState({
    labels: [],
    datasets: [{ data: [] }]
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: `Last ${totalDays} days`,
        },
      },
      y: {
        min: 0,
        title: {
          display: true,
          text: "Number of incidents",
        },
        ticks: {
          stepSize: Math.ceil(Math.max(...lineChartValuesAndLabels.datasets[0]?.data || [0]) / 5),
          callback: (value) => value, 
        },
      },
    },
  };  

  useEffect(() => {
    let fromDate = new Date();
    fromDate.setDate(new Date().getDate() - totalDays);
    fromDate.setHours(0, 0, 0, 0);
    let toDate = new Date();
    toDate.setDate(toDate.getDate() + 1);

    getIncidentsPerDay(fromDate.toISOString(), toDate.toISOString())
      .then(({ data }) => {
        const dataset = [];
        const currentDate = new Date();

        for (let i = 0; i < totalDays; i += 1) {
          const pastDate = new Date(currentDate);
          pastDate.setDate(currentDate.getDate() - totalDays + i + 1);
          let yesterday = pastDate.getDate();
          if (yesterday in data) {
            dataset[i] = {
              day: yesterday,
              count: data[yesterday]
            };
          } else {
            dataset[i] = { day: yesterday, count: 0 };
          }
        }
        setLineGraphValuesAndLabelsData(dataset);
      })
      .catch((error) => console.log(error));
  }, [totalDays]);

  function setLineGraphValuesAndLabelsData(data) {
    let valueArray = [];
    let labelArray = [];

    data.forEach((item, i) => {
      valueArray[i] = item.count;
      labelArray[i] = item.day;
    });
    setLineChartValuesAndLabels({
      labels: labelArray,
      datasets: [
        {
          data: valueArray,
          backgroundColor: "#FF4F4F",
          borderColor: "#FF4F4F",
          fill: false,
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        }
      ]
    });
  }
  return <Line options={options} data={lineChartValuesAndLabels} />;
}
