import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartWrapper = ({ data, labels, axisLabels, backgroundColor, indexAxis }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Incidents",
        data: data,
        backgroundColor: backgroundColor,
        borderColor: "rgba(60, 60, 60, 0)",
        borderWidth: 1,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    indexAxis: indexAxis,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
          drawTicks:false,
        },
        beginAtZero: true,
        title: {
          display: true,
          text: axisLabels.x,
        },
      },
      y: {
        grid: {
          drawOnChartArea: false,
          drawTicks:false
        },
        ticks: {
          callback: function (value, index) {
            const label = labels[index];
            if (label.length > 8) {
              return label.substring(0, 8) + "..."; // Trim label
            }
            return label;
          },
        },
        beginAtZero: true,
        title: {
          display: true,
          text: axisLabels.y,
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChartWrapper;
